<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-svg" @click="bookmark">
        <path d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3ZM17 18L12 15.82L7 18V6C7 5.45 7.45 5 8 5H16C16.55 5 17 5.45 17 6V18Z"/>
    </svg>
</template>

<script>
import api from "@/api"

export default {
    props: {
        _board: { type: String, default: null },
        code: { type: String, default: null }
    },
    methods: {
        async bookmark() {
            await api.v1.boards.bookmarks.post({ _board: this._board, code: this.code });
            alert("북마크 되었습니다")
        }
    }
}
</script>

<style>

</style>